/* global WcpConsent */
let siteConsent;
const wcp = window.WcpConsent;
const culture = document.documentElement.lang;
const bannerPlaceholder = 'ms-cookie-consent-banner';
const manageCookies = document.getElementById('ms-cookie-consent-manage');

const initCallback = (err, _siteConsent) => {
  if (!err) {
    siteConsent = _siteConsent;
  } else {
    if (window.logErrorFn) {
      window.logErrorFn(err)
    }
  }
};

const onConsentChanged = () => undefined;

if (wcp) {
  WcpConsent.init(
    culture,
    bannerPlaceholder,
    initCallback,
    onConsentChanged,
    WcpConsent.themes.dark
  );
}

if (siteConsent && siteConsent.isConsentRequired) {
  manageCookies.addEventListener('click', (e) => {
    e.preventDefault();
    siteConsent.manageConsent();
  });
} else {
  manageCookies.classList.add('d-none');
}
