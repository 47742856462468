import platform from 'platform';

var platformFamily = platform.os.family;
var platformManufacturer = platform.manufacturer;
var platformName = platform.name;
var platformProduct = platform.product;

var FAMILY_OSX = 'OS X';
var FAMILY_LINUXI86 = 'Linux i686';
var FAMILY_UBUNTU = 'Ubuntu';
var FAMILY_WINDOWS = 'Windows';
var FAMILY_WINDOWS_NT = 'Windows NT';
var FAMILY_IOS = 'iOS';
var FAMILY_WINDOWS_PHONE = 'Windows Phone';
var FAMILY_ANDROID = 'Android';

var FAMILY_PATTERN_WINDOWS = /windows/i;
var FAMILY_PATTERN_LINUX = /linux/i;

var MANUFACTURER_AMAZON = 'Amazon';
var MANUFACTURER_SONY = 'Sony';

var NAME_PLAYSTATION = 'PlayStation Browser';
var NAME_WII = 'Wii Browser';
var NAME_CHROME_BOOK = 'Chrome';

var PRODUCT_XBONE = 'Xbox One';
var PRODUCT_XB360 = 'Xbox 360';
var PRODUCT_PS4 = 'PlayStation 4';

var UA_PATTERN_KINDLE = /(kindle)|(silk)|(kf((tt)|(ot)|(jwa)|(jwi)|(sowi)|(thwa)|(thwi)|(apwa)|(apwi)))/i;

var isWindowsPlatform = function() {
  return FAMILY_PATTERN_WINDOWS.test(platformFamily);
};

var isLinuxPlatform = function() {
  return FAMILY_PATTERN_LINUX.test(platformFamily);
};

var isAmazonKindle = function() {
  return UA_PATTERN_KINDLE.test(platform.ua);
};

var getStorePlatform = function() {
  switch (platformFamily) {
    case FAMILY_OSX:
      return 'mac';
    case FAMILY_WINDOWS_NT:
    case FAMILY_WINDOWS:
      return 'windows';
    case FAMILY_LINUXI86:
    case FAMILY_UBUNTU:
      return 'linux';
    default:
      if (isWindowsPlatform()) {
        return 'windows';
      } else if (isLinuxPlatform()) {
        return 'linux';
      }
      return 'other';
  }
};

var getStorePlatformVersion = function() {
  return platform.os.version;
}

var getDownloadPlatform = function() {
  var getFromProductName = function() {
    switch (platformProduct) {
      case PRODUCT_XBONE:
        return 'xbone';
      case PRODUCT_XB360:
        return 'xb360';
      default:
        return 'none';
    }
  };

  var getFromPlatformName = function() {
    switch (platformName) {
      case NAME_PLAYSTATION:
        if (platformProduct.indexOf(PRODUCT_PS4) > -1) {
          return 'playstation4';
        }
        return 'playstation3';
      case NAME_WII:
        return 'nintendo';
      case NAME_CHROME_BOOK:
        return 'chromebook';
      default:
        return getFromProductName();
    }
  };

  switch (platformFamily) {
    case FAMILY_IOS:
      return 'ios';
    case FAMILY_ANDROID:
      if (platformManufacturer === MANUFACTURER_SONY) {
        return 'playstationvita';
      } else if (platformManufacturer === MANUFACTURER_AMAZON || isAmazonKindle()) {
        return 'amazon';
      }
      return 'android';
    case FAMILY_WINDOWS_PHONE:
      return 'windowsphone';
    case FAMILY_OSX:
    case FAMILY_WINDOWS:
    case FAMILY_WINDOWS_NT:
    case FAMILY_LINUXI86:
    case FAMILY_UBUNTU:
      return getStorePlatform();
    default:
      if (isWindowsPlatform()) {
        return 'windows';
      } else if (isLinuxPlatform()) {
        return 'linux';
      }
      return getFromPlatformName();
  }
};

var getSupportProvider = function() {
  var supportProvider = getDownloadPlatform();
  switch (supportProvider) {
    case 'mac':
    case 'windows':
    case 'linux':
    case 'none':
      return 'pc';
    default:
      return supportProvider;
  }
};

var getIsTouchDevice = function() {
  return window.ontouchstart !== undefined;
};

var getPocketDevicePlatform = function() {
  var downloadPlatform = getDownloadPlatform();
  if(downloadPlatform == 'android' || downloadPlatform == 'amazon' || downloadPlatform == 'ios' || (downloadPlatform == 'windows' && parseFloat(platform.os.version) >= 10.0))
    return downloadPlatform;
  else
    return null;
};



var getDeviceInformation = function() {
  return {
    'storePlatform': getStorePlatform(),
    'storePlatformVersion': getStorePlatformVersion(),
    'downloadPlatform': getDownloadPlatform(),
    'supportProvider': getSupportProvider(),
    'hasTouch' : getIsTouchDevice(),
    'pocketDevicePlatform': getPocketDevicePlatform()
  };
};

const deviceInfo = getDeviceInformation();
export default deviceInfo;
