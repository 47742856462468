/*jshint esversion: 6 */

// import 'bootstrap'
import 'whatwg-fetch';
import './nodelist-foreach-polyfill'
import 'url-search-params-polyfill'
import './bifrost-dependencies'
import AdobeAnalytics from './adobe-analytics'
import deviceInfo from './deviceinfo'
import './globalization'
import { Outliner } from './outliner'
import './site'
import './cookie-consent'
import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { getLogErrorFn } from './logErrorFn'

Sentry.init({
  dsn: 'https://13bb56e901f64a22a2858529aa9995c9@o339720.ingest.sentry.io/5443571',
  environment: window.siteEnv === 'prod' ? 'production' : 'stage',
  tracesSampleRate: 1.0,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error']
    })
  ],
});

const adobeAnalytics = new AdobeAnalytics(window.awa);

window.analyticsFns = [function () {}, adobeAnalytics.track.bind(adobeAnalytics)];
window.telemetryFn = adobeAnalytics.trackTelemetry.bind(adobeAnalytics);
window.deviceInfo = deviceInfo;
window.logErrorFn = getLogErrorFn(Sentry)

new Outliner({
  useDelegation: true,
  selector: '.btn'
});

const el = document.querySelectorAll('[data-role="sprite"]')[0];

if(el){
  const spriteUrl = el.getAttribute('data-src');
  if(spriteUrl){
    fetch(spriteUrl)
      .then(r => r.text())
      .then(text => {
          el.innerHTML = text;
      })
      .catch(console.error.bind(console));
  }
}