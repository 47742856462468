const BUY_SCENARIO_NAME = 'Buy Minecraft for PC/MAC';

const behaviorCodes = {
  'register': 'STARTPROCESS',
  'verify': 'PROCESSCHECKPOINT',
  'buy': 'PROCESSCHECKPOINT',
  'download': 'COMPLETEPROCESS',
  'ValidationError': 'OTHER',
  'Existing order': 'PURCHASE',
  'Modal': 'OTHER',
  'Purchase': 'PURCHASE',
  'PostRegistration': 'SIGNIN',
  'Dimension': 'OTHER'
};

const purchaseSteps = {
  'register': 1,
  'verify': 2,
  'buy': 3,
  'download': 4
};

const dimensions = {
  'Age': 'dimension1',
  'PaymentMethod': 'dimension2',
  'PaymentCountry': 'dimension3',
};

export default class AdobeAnalytics {

  constructor(awa) {
    this.awa = awa;
  }

  handleDimension(data) {

    if (dimensions[data.name]) {
      const dimensionOverrideValues = {
        behavior: this.getBehaviorCode('Dimension'),
        actionType: 'O',
        isManual: true,
        contentTags: {
          formid: 'Dimension - ' + data.name,
          formnm: data.name,
          field1: data.value
        }
      };

      this.awa.ct.captureContentPageAction(dimensionOverrideValues);
    }
  }

  handlePageView(data) {

    const urlNoParams =
      window.location.origin
      + window.location.pathname
      + window.location.hash.split('/')[0];

    const pagePath = data
      ? this.trimSlashes(data.pagePath)
      : '';

    let overrideValues = {
      actionType: 'O',
      uri: urlNoParams
    };

    if (purchaseSteps[pagePath]) {
      overrideValues.contentTags = {
        scn: BUY_SCENARIO_NAME,
        scnstp: pagePath,
        stpnum: purchaseSteps[pagePath]
      };

      this.awa.ct.captureContentPageAction(overrideValues);
    }

    this.awa.ct.capturePageView(overrideValues);
  }

  handleEvent(data) {

    const fields = data.label
      ? data.label.split(',')
      : [];

    let contentTags = {
      formnm: data.category + ' - ' + data.action
    };

    fields.forEach((field, i) => {
      contentTags[`field${i + 1}`] = field.trim();
    });

    let eventOverrideValues = {
      behavior: this.getBehaviorCode(data.category),
      actionType: 'O',
      isManual: true,
      contentTags: contentTags
    };

    if (data.category === 'Purchase' && data.action.includes('Placed Order')) {
      eventOverrideValues.contentTags.contentName = 'Purchase completed';
      this.awa.isTransaction = true;
      this.awa.ct.captureContentPageAction(eventOverrideValues);
      this.awa.isTransaction = false;
    } else {
      this.awa.ct.captureContentPageAction(eventOverrideValues);
    }
  }

  trackTelemetry(metadata, data) {
    if (metadata && metadata.type) {
      this.waitUntilReady(() => {
        this.track(metadata.type, data);
      });
    }
  }

  track(type, data) {

    if (!this.awa) {
      return;
    }

    if (type === 'Dimension') {
      this.handleDimension(data);
    }

    if (type === 'PageView') {
      this.handlePageView(data);
    }

    if (type === 'Event') {
      this.handleEvent(data);
    }
  }

  trimSlashes(str) {
    // Trim leading and trailing slashes from the supplied string
    return str.replace(/^\/*|\/*$/g, '');
  }

  getBehaviorCode(value) {
    let behavior = behaviorCodes[value];
    return this.awa.behavior[behavior];
  }

  waitUntilReady(trackFn, count = 0) {
    if (count > 100) {
      return;
    }
    if (this.awa && this.awa.isInitialized) {
      trackFn.apply(this);
      return;
    }

    count++;
    window.setTimeout(() => {
      this.waitUntilReady(trackFn, count)
    }, 100);
  }
}