'use strict';

import $ from 'jquery';

$(function () {
  if (window.deviceInfo.hasTouch) {
    $('body').addClass('has-touch');
  }

  $('a[href*="#"]:not([href="#"], [data-slide], [data-toggle])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash), scrollTop, stickyMenuHeight = 0, stickyMenu = $('.master-head--sticky');
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        if (stickyMenu.length) {
          stickyMenuHeight = parseInt(stickyMenu.height());
        }
        scrollTop = target.offset().top - stickyMenuHeight;
        $('html, body').animate({
          scrollTop: scrollTop
        }, 1000);
        return false;
      }
    }
  });

  var masterHead = $('.js-master-head');
  var originalMenuHeight = $('.master-head').height();


  var positionStickyMenu = function (scrollTop) {

    // Need to recalculate based on whether the cookie notice is shown or not
    var originalTop = $('.global-menu').height();
    masterHead.css('position', '');
    if (scrollTop >= originalTop) {
      masterHead.addClass('master-head--sticky');
      $('.site-body').css('margin-top', originalMenuHeight);
      masterHead.css('min-height', '');
      if ($(window).width() > 991) {

        var currentMenuHeight = $('.master-head').height();

        var minHeight = originalMenuHeight - (scrollTop - originalTop);
        if (minHeight > currentMenuHeight)
          masterHead.css('min-height', minHeight);

      }
    }
    else {
      masterHead.removeClass('master-head--sticky');
      masterHead.css('min-height', '');
      $('.site-body').css('margin-top', '');
    }

  };


  var onDocumentScroll = function (scrollTop) {
    positionStickyMenu(scrollTop);
  };

  $(window).scroll(function () {
    onDocumentScroll($(window).scrollTop());
  });

  onDocumentScroll($(window).scrollTop());

});
