import $ from 'jquery';

const CHECKOUT_RELOAD_FORM = 'checkout-reload'
const LANGUAGE_CHANGE_FORM = 'language-switcher'

$(function () {

  $('#language-switcher').find('a').click(function (e) {
    e.preventDefault();
    var form = document.querySelector('#' + CHECKOUT_RELOAD_FORM) || document.querySelector('#' + LANGUAGE_CHANGE_FORM);
    var languageCode = $(this).data('language-code');

    if (form.id === CHECKOUT_RELOAD_FORM) {
      checkoutReloadForm(form, languageCode);
    } else {
      langSwitcherForm($(form), languageCode);
    }

  });

  var langSwitcherForm = function ($form, languageCode) {
    $form.find('input[name="language"]').val(languageCode);
    $form.submit();
  };

  var checkoutReloadForm = function (form, languageCode) {
    var url = new URL(form.action);
    url.searchParams.set('lang', languageCode);
    form.action = url.toString();
    form.submit();
  };

  $('.ms-links__links--locale, .ms-footer__language-selector-close').click(function (e) {
    toggleLanguageSelector(e)
  });

  function toggleLanguageSelector(e) {
    e.preventDefault();
    
    $('#language-selector').slideToggle(260);
    $('html, body').animate({scrollTop: $(document).height()}, 260, 'linear');
  }
});